import React, { useEffect, useState } from "react"
import Layout from "@components/Layout"
import { graphql, Link as GatsbyLink } from "gatsby"
import {
  Grid,
  Box,
  Heading,
  AspectRatio,
  Flex,
  Avatar,
  Text,
  Stack,
  VStack,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Link as ChakraLink,
  Button,
  Input,
  useToast,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react"
import Img from "gatsby-image"
import { FaTwitter } from "react-icons/fa"
import { MdShare, MdContentCopy } from "react-icons/md"
import BlockContent from "@sanity/block-content-to-react"
import Copy from "@components/Copy"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { a11yDark, prism } from "react-syntax-highlighter/dist/esm/styles/prism"
import Codepen from "react-codepen-embed"
import SEO from "@components/SEO"
import dataNormaliser from "@utils/dataNormaliser"
import FeedbackableLogo from "../assets/svg/feedbackable.inline.svg"
import { FiExternalLink } from "react-icons/fi"

const dayjs = require("dayjs")
var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)

function Link({ children, isExternal, ...props }) {
  const afterBgColor = useColorModeValue("blue.50", "blue.900")
  const afterHoverBgColor = useColorModeValue("blue.100", "blue.800")
  return (
    <ChakraLink
      borderBottom="1px solid"
      borderBottomColor={"blue.500"}
      position="relative"
      display="inline-flex"
      alignItems="center"
      _after={{
        position: "absolute",
        left: 0,
        bottom: 0,
        height: "75%",
        background: afterBgColor,
        content: "''",
        width: "100%",
        zIndex: "-1",
        transition: "0.2s height ease",
      }}
      _hover={{
        textDecoration: "none",
        "&::after": {
          height: "100%",
          background: afterHoverBgColor,
        },
      }}
      {...props}
    >
      {children}
      {isExternal && <Box as={FiExternalLink} ml="1" fontSize="sm" />}
    </ChakraLink>
  )
}

const serializers = {
  types: {
    image: function ImageBlock(props) {
      // console.log({ image: props })
      const fluid = props.node.asset?.localFile?.childImageSharp?.fluid

      if (fluid) {
        return <Img fluid={fluid} />
      }
      if (props.node.asset?.localFile.src)
        return <img src={props.node.asset.localFile.src} />

      return (
        <Box bg="red.100" color="black">
          Missing image
        </Box>
      )
      // return <Image fluid={sanityAssetToImageFluid(props.node.asset)} />
    },
    codeInput: function CodeBlock(props) {
      const { colorMode } = useColorMode()
      return (
        <Box fontSize="1.125rem">
          <SyntaxHighlighter
            language={props.node.language}
            style={colorMode === "light" ? prism : a11yDark}
          >
            {props.node.code}
          </SyntaxHighlighter>
        </Box>
      )
    },
    codepen: function CodepenBlock(props) {
      const { url } = props.node
      const splitUrl = url.split("/")
      const [, , , user, , hash] = splitUrl

      return <Codepen hash={hash} user={user} defaultTab={`result`} />
    },
  },
  marks: {
    internalLink: function InternalLinkMark({ mark, children }) {
      const { slug = {}, type } = mark
      const slugBase = (() => {
        if (type === `post`) return `blog`
        if (type === `tutorial`) return `tutorials`
        return ``
      })()
      //console.log("slugbase", slugBase)
      const href = slugBase.length
        ? `/${slugBase}/${slug.current}`
        : `/${slug.current}`
      return (
        <Link as={GatsbyLink} to={href}>
          {children}
        </Link>
      )
    },
    link: function LinkMark({ mark, children }) {
      const { blank, href } = mark
      const linkProps = {
        href,
      }

      if (blank) {
        linkProps.target = "_blank"
        linkProps.rel = "noopener"
      }

      return (
        <Link isExternal {...linkProps}>
          {children}
        </Link>
      )
    },
  },
}

function normaliseQuery(_data) {
  return {
    post: dataNormaliser.normalisePost(_data.post),
  }
}

export default function Post({ data: _data }) {
  const data = normaliseQuery(_data)

  // console.log(data.post)

  const toast = useToast()

  const postMetaTextColor = useColorModeValue("gray.700", "gray.300")
  const postBorderColor = useColorModeValue("gray.300", "gray.700")
  const authorDetailsBgColor = useColorModeValue("gray.50", "gray.900")
  const authorDetailsHeaderBgColor = useColorModeValue("gray.100", "gray.800")

  const [postPublishedAtFromNow, setPostPublishedAtFromNow] = useState()
  useEffect(() => {
    setPostPublishedAtFromNow(dayjs(data.post?.publishedAt).fromNow())
  }, [])

  return (
    <Layout>
      <SEO
        title={data.post.seotitle || data.post.title}
        description={data.post.seotitle}
        ogImage={data?.post?.mainImage?.fluid?.src}
      />
      <Grid
        py="4"
        gap="4"
        // gridTemplateColumns={["100%", null, null, "6rem 1fr", "6rem 5fr 2fr"]}
        gridTemplateColumns={[
          "100%",
          null,
          null,
          "6rem 1fr 1fr 1fr",
          "6rem 4fr 4fr 3fr",
        ]}
        gridTemplateAreas={[
          `"aside" "main" "sidebar"`,
          null,
          null,
          `"aside main main sidebar"`,
        ]}
      >
        <Box gridArea="aside">
          <aside aria-label="Primary sidebar">
            <Stack
              spacing="4"
              direction={["row", null, null, "column"]}
              alignItems="center"
            >
              <Heading
                as="span"
                size="sm"
                fontWeight="bold"
                minW={[null, null, null, "100%"]}
              >
                Share
              </Heading>
              <Box minW={[null, null, null, "100%"]}>
                <Tooltip hasArrow label="Share to Twitter">
                  <IconButton
                    aria-label="Share post to Twitter"
                    icon={<FaTwitter />}
                    size="lg"
                    variant="ghost"
                    rounded="full"
                    colorScheme="blue"
                    as="a"
                    href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                      `"${data.post.title}" by @baffledbasti\nhttps://${process.env.GATSBY_SITE_URL}/blog/${data.post.slug}`
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                </Tooltip>
              </Box>
              <Box minW={[null, null, null, "100%"]}>
                {/* <Tooltip label="Sharing options"> */}
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={IconButton}
                    icon={<MdShare />}
                    aria-label="Open sharing options menu"
                    size="lg"
                    variant="ghost"
                    rounded="full"
                    colorScheme="blue"
                  />
                  <MenuList>
                    <MenuItem as="div">
                      <Input
                        readOnly={true}
                        value={`https://${process.env.GATSBY_SITE_URL}/blog/${data.post.slug}`}
                        onFocus={(e) => {
                          e.target.select()
                          e.target.setSelectionRange(0, 99999)
                        }}
                      />
                      <Tooltip label="Copy link">
                        <IconButton
                          icon={<MdContentCopy />}
                          variant="ghost"
                          onClick={() => {
                            try {
                              copyTextToClipboard(
                                `https://${process.env.GATSBY_SITE_URL}/blog/${data.post.slug}`
                              )
                              toast({
                                title: "Link copied.",
                                description: "Link copied to clipboard",
                                status: "success",
                                duration: 2000,
                                isClosable: true,
                              })
                            } catch (e) {
                              toast({
                                title: "Failed to copy link",
                                description:
                                  "Sorry, your browser does not support this.",
                                status: "error",
                                duration: 2000,
                                isClosable: true,
                              })
                            }
                          }}
                        />
                      </Tooltip>
                    </MenuItem>

                    <MenuItem
                      as="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                        `https://${process.env.GATSBY_SITE_URL}/blog/${data.post.slug}`
                      )}`}
                    >
                      Share to LinkedIn
                    </MenuItem>
                  </MenuList>
                </Menu>
                {/* </Tooltip> */}
              </Box>
            </Stack>
          </aside>
        </Box>
        <Box gridArea="main">
          <main role="main">
            <article>
              <Box
                overflow="hidden"
                border="1px solid"
                borderColor={postBorderColor}
              >
                <VStack spacing="8" flex>
                  <Box as="header" minW="100%">
                    {/* image + title + meta */}
                    <AspectRatio as="span" ratio={16 / 8} display="block">
                      <Box>
                        <Img
                          fluid={data.post.mainImage.fluid}
                          alt={data.post?.mainImage?.alt}
                          objectFit="cover"
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </Box>
                    </AspectRatio>
                    <VStack spacing="4" px={[4, 8]} pt="8">
                      <Heading as="h1" size="xl" fontWeight="bold" minW="100%">
                        {data?.post.title}
                      </Heading>
                      <Flex color={postMetaTextColor} minW="100%">
                        <Box w="2.5rem" mr="2">
                          <Avatar
                            bg="blue.900"
                            name={data.post.author.name}
                            size="sm"
                            w="2.5rem"
                            h="2.5rem"
                            src={data.post.author.image.fixed.src}
                          />
                        </Box>
                        <Box flexGrow="1">
                          <Text fontWeight="bold">{data.post.author.name}</Text>
                          <Text fontSize="sm">
                            {data.post?.publishedAtFormat} (
                            {postPublishedAtFromNow})
                          </Text>
                        </Box>
                        <Box alignSelf="flex-end">
                          <Text fontSize="sm">
                            {data.post?.readingTime?.text}
                          </Text>
                        </Box>
                      </Flex>
                    </VStack>
                  </Box>
                  <Box px={[4, 8]} pb="8" minW="0" maxW="100%">
                    <Copy
                      as={BlockContent}
                      blocks={data.post.body}
                      serializers={serializers}
                    />
                  </Box>
                </VStack>
              </Box>
            </article>
          </main>
          {!!data.post?.related?.map && (
            <Box
              mt="4"
              border="1px solid"
              borderColor={postBorderColor}
              overflow="hidden"
              px={[4, 8]}
              py="4"
            >
              <Heading
                as="span"
                fontSize="1.5rem"
                fontWeight="bold"
                mb="6"
                display="block"
              >
                More posts
              </Heading>
              <VStack spacing="6">
                {data.post.related.map((post, index) => (
                  <Box key={index} minW="100%">
                    <GatsbyLink to={`/blog/${post.slug}`}>
                      <Heading as="span" size="lg" mb="2" display="block">
                        {post.title}
                      </Heading>
                      <Flex
                        alignItems="center"
                        color={postMetaTextColor}
                        flexWrap="wrap"
                        as="span"
                      >
                        <Text fontWeight="bold" mr="6">
                          {post.author.name}
                        </Text>
                        <Text fontSize="sm">
                          {post?.publishedAtFormat} ({post?.publishedAtFromNow})
                        </Text>
                      </Flex>
                    </GatsbyLink>
                  </Box>
                ))}
              </VStack>
            </Box>
          )}
        </Box>
        <Box>
          <aside aria-label="Additional sidebar">
            <VStack spacing="4">
              <Box
                minW="100%"
                border="1px solid"
                borderColor={postBorderColor}
                rounded="md"
                overflow="hidden"
                bg={authorDetailsBgColor}
              >
                <Flex
                  bg={authorDetailsHeaderBgColor}
                  px="4"
                  py="2"
                  alignItems="center"
                >
                  <Box w="2.5rem" mr="2">
                    <Avatar
                      bg="blue.900"
                      name={data.post.author.name}
                      size="sm"
                      w="2.5rem"
                      h="2.5rem"
                      src={data.post.author.image.fixed.src}
                    />
                  </Box>
                  <Box flexGrow="1">
                    <Text fontWeight="bold">{data.post.author.name}</Text>
                  </Box>
                </Flex>
                <Box px="4" py="2">
                  <VStack spacing="2">
                    <Text size="sm" minW="100%">
                      Self-taught developer. Helping developers and aspiring
                      developers learn to code!
                    </Text>

                    <Button
                      colorScheme="twitter"
                      leftIcon={<FaTwitter />}
                      href="https://twitter.com/baffledbasti"
                      target="_blank"
                      rel="noopener noreferrer"
                      as="a"
                    >
                      Follow me
                    </Button>
                  </VStack>
                </Box>
              </Box>
              {/* <Box
                minW="100%"
                border="1px solid"
                borderColor="purple.500"
                rounded="md"
                overflow="hidden"
              >
                <Flex
                  bg="blue"
                  px="4"
                  py="2"
                  color="gray.400"
                  alignItems="center"
                >
                  <Box w="2.5rem" mr="2">
                    <Box w="2.5rem" h="2.5rem" rounded="full" overflow="hidden">
                      <Box as={FeedbackableLogo} w="100%" h="100%" />
                    </Box>
                  </Box>
                  <Box flexGrow="1">
                    <Text fontWeight="bold" color="pink.300">
                      Shameless Plug
                    </Text>
                  </Box>
                </Flex>
                <Box px="4" py="2">
                  <VStack spacing="2">
                    <Text size="sm" minW="100%">
                      Managing client feedback sucks.
                    </Text>
                    <Text size="sm" minW="100%" fontWeight="bold">
                      Do it better with Feedbackable
                    </Text>
                  </VStack>
                  <Flex mt="6" justifyContent="center">
                    <Button
                      leftIcon={<FiExternalLink />}
                      colorScheme="pink"
                      href="https://bit.ly/3h7jwHk"
                      target="_blank"
                      as="a"
                    >
                      Try Feedbackable.io
                    </Button>
                  </Flex>
                </Box>
              </Box> */}
            </VStack>
          </aside>
        </Box>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query PostQuery($slug: String) {
    post: sanityPost(slug: { current: { eq: $slug } }) {
      id
      author {
        name
        image {
          asset {
            localFile {
              childImageSharp {
                fixed(width: 64) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
      categories {
        color
        color_custom {
          hex
        }
        title
        slug {
          current
        }
      }
      slug {
        current
      }
      title
      publishedAt
      publishedAtFromNow: publishedAt(fromNow: true)
      publishedAtFormat: publishedAt(formatString: "MMM D Y")
      mainImage {
        alt
        asset {
          localFile {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      body {
        _key
        _type
        alt
        code
        language
        level
        listItem
        style
        url
        markDefs {
          _key
          _type
          blank
          href
          reference {
            slug {
              current
            }
            title
          }
        }
        children {
          _key
          _type
          marks
          text
        }
        asset {
          localFile {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      seotitle
      seodescription
      readingTime {
        text
      }
      related {
        title
        slug {
          current
        }
        author {
          name
        }
        publishedAtFromNow: publishedAt(fromNow: true)
        publishedAtFormat: publishedAt(formatString: "MMM D Y")
      }
    }
  }
`

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement("textarea")
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = "0"
  textArea.style.left = "0"
  textArea.style.position = "fixed"

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  let err = null
  try {
    document.execCommand("copy")
  } catch (_err) {
    err = _err
  }

  document.body.removeChild(textArea)
  if (err) {
    throw err
  }
  return null
}
function copyTextToClipboard(text) {
  return new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
      try {
        fallbackCopyTextToClipboard(text)
        return resolve()
      } catch (err) {
        return reject(err)
      }
    }
    navigator.clipboard.writeText(text).then(
      function () {
        return resolve()
      },
      function (err) {
        return reject(err)
      }
    )
  })
}
