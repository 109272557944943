import React, { useEffect, useState } from "react"
import { chakra, useColorModeValue } from "@chakra-ui/react"
import { useTheme } from "@emotion/react"

export const CopyStyled = chakra("div", {
  baseStyle: {
    fontSize: ["lg", null, null, "xl"],
    "&>*": {
      my: 6,
    },
    "&>*:first-of-type": {
      mt: 0,
    },
    "&>*:last-child": {
      mb: 0,
    },
    h1: {
      fontSize: "5xl",
    },
    h2: {
      fontSize: "4xl",
    },
    h3: {
      fontSize: "3xl",
    },
    h4: {
      fontSize: "2xl",
    },
    "*+h1": {
      mt: 12,
    },
    "*+h2": {
      mt: 10,
    },
    "*+h3": {
      mt: 8,
    },
    "*+h4": {
      mt: 8,
    },
    "ul,ol": {
      listStylePosition: "inside",
    },
    code: {
      bg: "var(--code-bg-color)",
      px: "1",
    },
  },
})

const useThemeColorWithMode = (lightValue, darkValue) => {
  const value = useColorModeValue(lightValue, darkValue)
  const theme = useTheme()

  const [color, setColor] = useState()

  useEffect(() => {
    const parts = value.split(".")
    setColor(theme.colors[parts[0]][parts[1]])
  }, [value])

  return color
}

export default function Copy(props) {
  const codeBgColor = useThemeColorWithMode("gray.100", "gray.600")

  return (
    <CopyStyled
      {...props}
      sx={{ ...props?.sx, "--code-bg-color": codeBgColor }}
    />
  )
}
